import React from "react";
import {
  usePelcro,
  PelcroModalController,
  Dashboard,
  // DashboardOpenButton,
  SelectModal,
  LoginModal,
  RegisterModal,
  PaymentMethodUpdateModal,
  PaymentMethodSelectModal,
  PaymentMethodCreateModal,
  SubscriptionCreateModal,
  SubscriptionRenewModal,
  SubscriptionCancelModal,
  SubscriptionSuspendModal,
  SubscriptionManageMembersModal,
  NewsLetter,
  NewsletterUpdateModal,
  PaymentSuccessModal,
  MeterModal,
  UserUpdateModal,
  AddressCreateModal,
  AddressUpdateModal,
  PasswordResetModal,
  PasswordForgotModal,
  PasswordlessRequestModal,
  VerifyLinkTokenModal,
  CartModal,
  ShopView,
  OrderConfirmModal,
  OrderCreateModal,
  GiftCreateModal,
  GiftRedeemModal,
  PasswordChangeModal,
  AddressSelectModal,
  ProfilePicChangeModal,
  EmailVerifyModal,
  InvoiceDetailsModal,
  InvoicePaymentModal,
  Notification,
  // QrCodeModal
} from "@pelcro/react-pelcro-js";
import "./App.css";

const options = { enableGoogleAnalytics: true };

export default () => {
  const { switchView, view, setPlan, setProduct, product, plan } = usePelcro();

  window.pelcroActions = {
    login: () => switchView("login"),
    subscribe: () => switchView("plan-select"),
    dashboard: () => switchView("dashboard"),
    close: () => switchView(""),
    setPlan: (planId) => setPlan(planId),
    setProduct: (productId) => setProduct(productId),
    subscriptionCreate: () => switchView('subscription-create')
  }

  const showDashboard = () => {
    console.log('showDashboard')
    switchView("dashboard");
  }

  // Use callback or memo to avoid re-rendering
  const logComponent = (name) => {
    console.log(name)
  }

  // const products = window.Pelcro.product.list();
  // console.log('PELCRO PRODUCTS', products);

  const onCloseLogin = () => {
    const userLogin = document.querySelectorAll('.userLoginBtn, .myAccountBtn')

    if (userLogin?.length > 0) {
      userLogin.forEach((btn) => {
        btn.setAttribute('aria-expanded', false);
        btn.setAttribute('aria-hidden', true);
      })
    }
  }

  const onCloseDashboard = () => {
    console.log('dashboard close')
    const myAccountBtn = document.querySelectorAll('.myAccountBtn')

    if (myAccountBtn?.length > 0) {
      myAccountBtn.forEach((btn) => {
        btn.setAttribute('aria-expanded', false);
        btn.setAttribute('aria-hidden', true);
      })
    }
  }

  console.log('Current view:', view, 'Current product:', product, 'Current plan:', plan)

  return (
    <div className="App">
      <PelcroModalController options={options}>
        <MeterModal />
        <LoginModal onDisplay={() => logComponent('login')} onSuccess={showDashboard} onClose={onCloseLogin}/>
        <VerifyLinkTokenModal />
        <RegisterModal />
        <EmailVerifyModal />

        <UserUpdateModal />
        <Dashboard onDisplay={() => logComponent('dashboard open')} onClose={onCloseDashboard} />
        {/*<DashboardOpenButton />*/}
        <ProfilePicChangeModal />

        <PasswordForgotModal />
        <PasswordlessRequestModal />
        <PasswordChangeModal />
        <PasswordResetModal />
        {/*<QrCodeModal />*/}

        <PaymentMethodCreateModal />
        <PaymentMethodUpdateModal />
        <PaymentMethodSelectModal />

        <SelectModal />
        <NewsLetter/>
        <NewsletterUpdateModal />

        <AddressCreateModal />
        <AddressSelectModal />
        <AddressUpdateModal />

        <SubscriptionCreateModal />
        <SubscriptionRenewModal />
        <SubscriptionCancelModal />
        <SubscriptionSuspendModal/>
        <SubscriptionManageMembersModal/>
        <PaymentSuccessModal />

        <GiftCreateModal />
        <GiftRedeemModal />

        <ShopView />
        <CartModal />
        <OrderCreateModal />
        <OrderConfirmModal />

        <InvoiceDetailsModal/>
        <InvoicePaymentModal/>

        <Notification />
      </PelcroModalController>
    </div>
  );
}

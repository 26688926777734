import address from "./address.json";
import cart from "./cart.json";
import checkoutForm from "./checkoutForm.json";
import common from "./common.json";
import dashboard from "./dashboard.json";
import InvoiceDetails from "./InvoiceDetails.json";
import login from "./login.json";
import message from "./messages.json";
import meter from "./meter.json";
import newsletter from "./newsletter.json";
import notification from "./notification.json";
import passwordChange from "./passwordChange.json";
import passwordForgot from "./passwordForgot.json";
import passwordlessRequest from "./passwordlessRequest.json";
import passwordReset from "./passwordReset.json";
import payment from "./payment.json";
import paymentMethod from "./paymentMethod.json";
import register from "./register.json";
import select from "./select.json";
import shop from "./shop.json";
import subscriptionCancel from "./subscriptionCancel.json";
import subscriptionManageMembers from "./subscriptionManageMembers.json";
import subscriptionSuspend from "./subscriptionSuspend.json";
import success from "./success.json";
import userEdit from "./userEdit.json";
import verifyEmail from "./verifyEmail.json";
import verifyLinkToken from "./verifyLinkToken.json";

const en = {
	address,
	cart,
	checkoutForm,
	common,
	dashboard,
	InvoiceDetails,
	login,
	message,
	meter,
	newsletter,
	notification,
	passwordChange,
	passwordForgot,
	passwordlessRequest,
	passwordReset,
	payment,
	paymentMethod,
	register,
	select,
	shop,
	subscriptionCancel,
	subscriptionManageMembers,
	subscriptionSuspend,
	success,
	userEdit,
	verifyEmail,
	verifyLinkToken
}

export default en

import React from "react";
import ReactDOM from "react-dom";
import { i18n } from "@pelcro/react-pelcro-js";
import "@pelcro/react-pelcro-js/dist/pelcro.css";
import App from "./App";
import en from "./translations/en";
import el from "./translations/el";

const bundles = ['address', 'cart', 'checkoutForm', 'common', 'dashboard', 'InvoiceDetails', 'login', 'messages', 'meter', 'newsletter', 'notification', 'passwordChange', 'passwordForgot', 'passwordlessRequest', 'passwordReset', 'payment', 'paymentMethod', 'register', 'select', 'shop', 'subscriptionCancel', 'subscriptionManageMembers', 'subscriptionSuspend', 'success', 'userEdit', 'verifyEmail', 'verifyLinkToken']

bundles.forEach((bundle) => {
  i18n.addResourceBundle('en-AU', bundle, en[bundle], true, true);
  i18n.addResourceBundle('el', bundle, el[bundle], true, true);
})

// i18n.changeLanguage('el');

const root = document.getElementById("root");
if (root) {
  ReactDOM.render(
    <React.StrictMode> <App/> </React.StrictMode>,
    root
  );
}

const subscriptions = document.getElementById("nk-subscriptions");
if (subscriptions) {
  ReactDOM.render(
    <React.StrictMode> <App/> </React.StrictMode>,
    subscriptions
  );
}
